.grid-ingredients {
  width:100%;
  display: grid;
  grid-template-columns: 100%;
  grid-auto-rows: 4% 33% 63%; /* new */
  background-color: #0b7625;
  padding: 0px 10px 10px 10px;
  height: 93.7vh;
}
.grid-item-ingredients {
  background-color: rgb(255, 255, 255);
  /*border: 1px solid rgba(0, 0, 0, 0.8);*/
  padding: 20px;
  font-size: 30px;
  text-align: center;
}





.grid-item-title {

  padding: 0px 0px 0px 25px !important;
  font-size: 2.4em;
  font-weight: bold;
  text-align: left;
}



.grid-info-item-ingredients-info {
  padding: 0px 0px 0px 0px !important;
}
.grid-info-item-ingredients-diagram {
  padding: 0px 0px 0px 25px !important;
}
  .grid-info-ingredients {
    width:100%;
    display: grid;
    grid-template-columns: 60%  20% auto;
    grid-auto-rows: 100%; /* new */
    /*background-color: #6ef321;*/
    padding: 0px;
    overflow: hidden; /* new */
    height: 100%;
  }

  .grid-info-item-ingredients {
    background-color: rgba(255, 255, 255, 0.8);
    /*border: 1px solid rgba(0, 0, 0, 0.8);*/
    padding: 20px;
    font-size: 30px;
    text-align: center;
  }


  .scrollableDiv {
    height: 47vh; /* Adjust based on your design */
    overflow: auto;
  }
  
