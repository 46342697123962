.ingredient-info-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: 1px solid #e5e5e5;
    font-size: 14px;

    
}
.ingredient-info-list-details {
        width:100%;
        display: grid;
        grid-template-columns: 50% auto;
        grid-auto-rows: 18% auto; /* new */
        /*background-color: #2196F3;*/
        overflow: auto; /* new */
        height: 100%;
}
.grid-ingredient-item{  
        font-size: 30px;
        text-align: center;
}

.ingredient-item-name{
    border-bottom: 1px solid rgba(0, 0, 0, 0.289);
    text-align: center;
    padding-left: 10px;
    font-size: 28px;
    font-weight: 600;
    grid-column: span 2; /* Span across one column */
    grid-row: span 1; /* Span across one row */
}
.ingredient-item-info{
    grid-column: span 1; /* Span across one column */
    grid-row: span 1; /* Span across one row */
    padding: 10px 20px;
}
.ingredient-item-tag{
    font-size: 20px;
    text-align: left;
    padding-left: 25px;
    padding-top: 10px;
    grid-column: span 1; /* Span across one column */
    grid-row: span 1; /* Span across one row */
}