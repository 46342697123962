.grid-prepmeal {
  width:100%;
  display: grid;
  grid-template-columns: 100%;
  grid-auto-rows: 4% 33% 63%; /* new */
  background-color: #0b7625;
  padding: 0px 10px 10px 10px;
  height: 93.7vh;
}









.grid-item-prepmeal-selected {
  grid-row: span 2; /* Span across two rows */
}

.grid-prepmeal-info-effect {
  display: block; /* Display when visible */
  opacity: 1;
  max-height: 1000px; /* Adjust to a value taller than your content */
}

.grid-prepmeal-info-effect.nonselected {
  display: none; /* Initially hidden */
  opacity: 0;
  transition: opacity 0.3s ease, max-height 0.3s ease; /* Transition opacity and max-height */
  max-height: 0;
  overflow: hidden;
}

.grid-item-prepmeal {

  background-color: rgba(255, 255, 255);
  padding: 20px;
  font-size: 30px;
  text-align: center;
}


.grid-item-title {

  padding: 0px 0px 0px 25px !important;
  font-size: 2.4em;
  font-weight: bold;
  text-align: left;
}


.grid-info-item-prepmeal-info {
  padding: 0px 0px 0px 0px !important;
}
.grid-info-item-prepmeal-diagram {
  padding: 0px 0px 0px 25px !important;
}
  .grid-info-prepmeal {
    width:100%;
    display: grid;
    grid-template-columns: 60%  20% auto;
    grid-auto-rows: 100%; /* new */
    /*background-color: #6ef321;*/
    padding: 0px;
    overflow: hidden; /* new */
    height: 100%;
  }

  .grid-info-item-prepmeal {
    background-color: rgba(255, 255, 255, 0.8);
    /*border: 1px solid rgba(0, 0, 0, 0.8);*/
    padding: 20px;
    font-size: 30px;
    text-align: center;
  }

  .prepmeal-card-scroll {
    height:95%;
    overflow-y: auto;
  }

  .prepmeal-card-inside {
    display:flex;
    min-height:3000px;
  }