.PrepMeal {
    position: absolute;
    top: 5px;
    left: 20px;
    height: 40px;
    display: flex;
    z-index: 1001;
}
.HeaderInner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
}

menu-Top {
    display: block;
}


.menu-item {
    color:rgb(0,59,73) !important;
}
.menu-active{
    color:rgba(0,59,73, 0.3) !important;
    text-shadow: 0 0 5px rgba(0,59,73, 0.8); 
}


.primary-color {
    color: rgb(35,173,106)
}
.primary2-color {
    color: rgb(0,59,73)
}
.second-color {
    color: rgb(0,86,78)
}
.thirt-color {
    color: rgb(3,123,112)
}